import { Parallax } from "react-parallax";
import { Countdown } from "../Components/Countdown";
import { RSVP } from "../Components/RSVP";
import { useMainContext } from "../Context/MainContext";
import {
  DrawingIcon,
  FlowerIcon,
  drawings,
  flowers,
} from "../Components/CustomIcon";
import { Slider } from "../Components/Slider";
import { useBreakpoint } from "../Hooks/useBreakpoint";
import { CopyItem } from "../types";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";

const TimelineItem = ({
  time,
  title,
  icon,
}: {
  time: string;
  title: string;
  icon: string;
}) => {
  return (
    <div className="flex-1 bg-white p-6 flex flex-col justify-center items-center">
      <div className="bg-gray-100 rounded-full p-3 flex items-center justify-center w-24 h-24">
        <DrawingIcon
          icon={icon}
          size="text-5xl"
          color="black"
          hoverColor="black"
        />
      </div>
      <div className="ltr:font-davidlibre rtl:font-bonanova mt-2 text-center">
        <span className="text-2xl md:text-3xl block mt-2 text-main-green">
          {time}
        </span>
        <span className="text-[16px] md:text-xl block mt-1 text-main">
          {title}
        </span>
      </div>
    </div>
  );
};

const InformationItem = ({
  label,
  title,
  text,
  button = "View More",
  buttonAction,
}: {
  label: string;
  title: string;
  text: string;
  button?: string;
  buttonAction: () => void;
}) => {
  return (
    <div className="flex-1 bg-white p-6 flex flex-col justify-center items-center">
      <div className="flex flex-col items-center justify-center h-full">
        {/* <div className="mt-2 w-full">
          <span className="superheadline text-[16px] md:text-xl ltr:font-davidlibre rtl:font-bonanova w-max mx-auto text-main-yellow flex items-center relative uppercase">
            {label}
          </span>
        </div> */}
        <h2 className="mt-3 text-3xl ltr:font-davidlibre rtl:font-bonanova font-medium">
          <span className="block text-main-green">{title}</span>
        </h2>
      </div>
      <div className="mt-3 mb-1 mx-auto text-center text-main text-lg">
        {text}
      </div>
      <div
        className="relative viewmore mt-3 mb-1 mx-auto text-center text-main/90 hover:text-main/80 cursor-pointer text-base"
        onClick={buttonAction}
      >
        {button}
      </div>
    </div>
  );
};

const InformationSection = () => {
  const { lang } = useMainContext();
  const copy: CopyItem = {
    SUPER: {
      en: "Arriving, Staying & Exploring",
      hr: "Dolazak, boravak i Zanimljivosti",
      he: "הגעה ולינה"
    },
    TITLE: {
      en: "Additional Info",
      hr: "Dodatne informacije",
      he: "מידע נוסף",
    },
    ARRIVING_SUPER: {
      en: "Arrive",
      hr: "Dolazak",
      he: "להגיע",
    },
    ARRIVING_TITLE: {
      en: "Getting There",
      hr: "Kako doći",
      he: "אפשרויות הגעה"
    },
    ARRIVING_TEXT: {
      en: "The Lorenz venue is located just an hour away from Vienna, and is well connected by road and rail",
      hr: "Lokacija Lorenz nalazi se samo sat vremena vožnje od Beča, a dobro je povezana cestom i željeznicom",
      he: "מקום האירוע נמצא במרחק שעה נסיעה מווינה, וישנם אפשרויות שונות להגעה באמצעות רכב, רכבת ושאטל",
    },
    STAY_SUPER: {
      en: "Stay",
      hr: "Boravak",
      he: "לישון",
    },
    STAY_TITLE: {
      en: "Accommodation",
      hr: "Smještaj",
      he: "אפשרויות לינה",
    },
    STAY_TEXT: {
      en: "As a popular destination, the Wachau valley offers a wide range of accommodation options",
      hr: "Kao popularna destinacija, dolina Wachau nudi širok spektar smještajnih mogućnosti ako želite ostati u području",
      he: "כיעד פופולרי, עמק הווכאו מציע מגוון רחב של אפשרויות אירוח במידה ותרצו להשאר באיזור",
    },
    EXPLORE_SUPER: {
      en: "Explore",
      hr: "Istraživanje",
      he: "לטייל",
    },
    EXPLORE_TITLE: {
      en: "Discover Wachau",
      hr: "Otkrijte Wachau",
      he: "טיול בווכאו",
    },
    EXPLORE_TEXT: {
      en: "Known for its stunning landscapes and picturesque villages, Wachau has a lot to offer",
      hr: "Poznata po prekrasnim krajolicima i slikovitim selima, dolina Wachau ima mnogo toga za ponuditi",
      he: "עמק הווכאו ידוע בנופיו המהממים והכפרים הציוריים, ומציע אפשרויות רבות לטיולים",
    },
    VIEW_MORE: {
      en: "View More",
      hr: "Pogledaj više",
      he: "עוד",
    },
  };
  return (
    <div className="relative bg-main-light pt-24 md:pt-0 pb-20 px-0 sm:px-6 lg:pb-28 lg:px-8">
      <div className="relative max-w-[90%] md:max-w-4xl mx-auto mt-52">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="mt-2 w-full">
            <span className="superheadline text-[16px] md:text-xl ltr:font-davidlibre rtl:font-bonanova w-max mx-auto text-main-yellow flex items-center relative uppercase">
              {copy.SUPER[lang]}
            </span>
          </div>
          <h2 className="mt-4 text-4xl md:text-5xl ltr:font-davidlibre rtl:font-bonanova font-medium">
            <span className="block text-main-green">{copy.TITLE[lang]}</span>
          </h2>
        </div>
        <div className="mt-6 md:mt-12 max-w-lg mx-auto grid lg:grid-cols-3 lg:max-w-none divide-x divide-black/10 shadow-xl">
          <InformationItem
            label={copy.ARRIVING_SUPER[lang]}
            title={copy.ARRIVING_TITLE[lang]}
            text={copy.ARRIVING_TEXT[lang]}
            button={copy.VIEW_MORE[lang]}
            buttonAction={() => {}}
          />
          <InformationItem
            label={copy.STAY_SUPER[lang]}
            title={copy.STAY_TITLE[lang]}
            text={copy.STAY_TEXT[lang]}
            button={copy.VIEW_MORE[lang]}
            buttonAction={() => {}}
          />
          <InformationItem
            label={copy.EXPLORE_SUPER[lang]}
            title={copy.EXPLORE_TITLE[lang]}
            text={copy.EXPLORE_TEXT[lang]}
            button={copy.VIEW_MORE[lang]}
            buttonAction={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

const TimelineSection = () => {
  const { lang } = useMainContext();
  const copy: CopyItem = {
    SUPER: {
      en: "Our Day's Timeline",
      hr: "Raspored našeg dana",
      he: "יום החתונה",
    },
    TITLE: {
      en: "Wedding Schedule",
      hr: "Raspored vjenčanja",
      he: "לוח זמנים",
    },
    RECEPTION: {
      en: "Reception",
      hr: "Recepcija",
      he: "קבלת פנים",
    },
    CEREMONY: {
      en: "Ceremony",
      hr: "Ceremonija",
      he: "טקס",
    },
    DINNER: {
      en: "Dinner",
      hr: "Večera",
      he: "ארוחת ערב",
    },
    PARTY: {
      en: "Party",
      hr: "Zabava",
      he: "מסיבה",
    },
  };
  return (
    <div className="relative bg-main-light pt-80 pb-20 px-0 sm:px-6 lg:pb-28 lg:px-8">
      <div className="relative max-w-[90%] md:max-w-4xl mx-auto mt-52">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="w-full flex items-center justify-center mb-2">
            <FlowerIcon icon={flowers["F8"]} />
          </div>
          <div className="mt-2 w-full">
            <span className="superheadline text-[16px] md:text-xl ltr:font-davidlibre rtl:font-bonanova w-max mx-auto text-main-yellow flex items-center relative uppercase">
              {copy.SUPER[lang]}
            </span>
          </div>
          <h2 className="mt-4 text-4xl md:text-5xl ltr:font-davidlibre rtl:font-bonanova font-medium">
            <span className="block text-main-green">{copy.TITLE[lang]}</span>
          </h2>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid lg:grid-cols-4 lg:max-w-none divide-x divide-black/10 shadow-xl">
          <TimelineItem
            time={`4:00${lang !== "he" ? "pm" : ""}`}
            title={copy.RECEPTION[lang]}
            icon={drawings["HandFlower (drawings set)"]}
          />
          <TimelineItem
            time={`5:30${lang !== "he" ? "pm" : ""}`}
            title={copy.CEREMONY[lang]}
            icon={drawings["Ring (drawings set)"]}
          />
          <TimelineItem
            time={`7:00${lang !== "he" ? "pm" : ""}`}
            title={copy.DINNER[lang]}
            icon={drawings["Lunch (drawings set)"]}
          />
          <TimelineItem
            time={`8:30${lang !== "he" ? "pm" : ""}`}
            title={copy.PARTY[lang]}
            icon={drawings["Champagne (drawings set)"]}
          />
        </div>
      </div>
    </div>
  );
};

const CountdownSection = () => {
  const { screen } = useBreakpoint();
  const { lang } = useMainContext();
  const copy: CopyItem = {
    SUPER: {
      en: "Almost There",
      hr: "Samo još malo",
      he: "כמעט שם",
    },
    TITLE_ONE: {
      en: "We Can't Wait",
      hr: "Jedva",
      he: "מחכים",
    },
    TITLE_TWO: {
      en: "to See You",
      hr: "čekamo",
      he: "לראותכם",
    },
  };
  return (
    <div className="relative bg-main-light pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
      <div className="relative max-w-4xl mx-auto">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="mt-2 w-full">
            <span className="superheadline text-[16px] md:text-xl ltr:font-davidlibre rtl:font-bonanova w-max mx-auto text-main-yellow flex items-center relative uppercase">
              {copy.SUPER[lang]}
            </span>
          </div>
          <h2 className="mt-4 text-3xl md:text-5xl ltr:font-davidlibre rtl:font-bonanova font-medium">
            <span className="block text-center text-main-green">
              {copy.TITLE_ONE[lang]} {screen === "mobile" ? <br /> : <></>}
              {copy.TITLE_TWO[lang]}
            </span>
          </h2>
        </div>
        <div className="mt-12 max-w-lg mx-auto lg:max-w-none">
          <Countdown />
        </div>
      </div>
    </div>
  );
};

const RsvpSection = () => {
  const { formDivHeight, rsvpRef, submitted, lang } = useMainContext();
  const { screen } = useBreakpoint();

  const copy: CopyItem = {
    SUPER: {
      en: "Kindly Reply",
      hr: "Ljubazno odgovorite",
      he: "שאלון קצר",
    },
    TITLE: {
      en: "RSVP",
      hr: "Potvrda Dolaska",
      he: "אישור הגעה",
    },
    SUB: {
      en: "Please confirm your attendance on or before 1st of April",
      hr: "Molimo potvrdite svoj dolazak do 1. travnja",
      he: "אנא אשרו את הגעתכם עד ה-1 באפריל",
    },
  };
  return (
    <div
      ref={rsvpRef}
      style={{
        marginBottom: submitted && screen === "mobile" ? 200 : formDivHeight,
      }}
    >
      <Parallax
        bgImage="/images/rsvp-bg.jpg"
        bgImageStyle={{ objectFit: "cover" }}
        bgImageAlt="the cat"
        strength={200}
      >
        <div style={{ height: "550px" }} />
      </Parallax>
      <div
        id="rsvp"
        className="absolute left-1/2 -translate-x-1/2 bg-white z-50 max-w-[90%] md:max-w-4xl w-full md:p-6 -mt-40 shadow-lg"
      >
        <div className="relative max-w-4xl mx-auto">
          <div className="grid md:grid-cols-2 gap-5">
            <div className="flex flex-col items-center justify-center h-full bg-main-tint pt-8 pb-12">
              <div className="w-full flex items-center justify-center mb-2">
                <FlowerIcon icon={flowers["F11"]} />
              </div>
              <div className="mt-2 w-full">
                <span className="superheadline text-[16px] md:text-xl ltr:font-davidlibre rtl:font-bonanova w-max mx-auto text-main-yellow flex items-center relative uppercase">
                  {copy.SUPER[lang]}
                </span>
              </div>
              <h2 className="mt-4 text-4xl md:text-5xl ltr:font-davidlibre rtl:font-bonanova font-medium">
                <span className="block text-main-green">
                  {copy.TITLE[lang]}
                </span>
              </h2>
              <div className="mx-auto text-center text-main text-lg max-w-[250px] px-2 mt-5">
                {copy.SUB[lang]}
              </div>
            </div>
            <RSVP />
          </div>
        </div>
      </div>
    </div>
  );
};

const LocationAndTimeSection = () => {
  const { lang } = useMainContext();

  const copy: CopyItem = {
    SUPER: {
      en: "Where & When",
      hr: "Gdje i kada",
      he: "איפה ומתי",
    },
    TITLE: {
      en: "Save The Date",
      hr: "Spremite datum",
      he: "שמרו את התאריך",
    },
    SUB: {
      en: "Together with our families, we invite you to our wedding ceremony and celebration this spring.",
      hr: "Zajedno s našim obiteljima, pozivamo vas na našu vjenčanu ceremoniju i proslavu ovog proljeća.",
      he: "אנו מזמינים אתכם לחתונה שלנו באביב הקרוב.",
    },
    WHEN: {
      en: "When",
      hr: "Kada",
      he: "מתי",
    },
    WHERE: {
      en: "Where",
      hr: "Gdje",
      he: "איפה",
    },
    TIME: {
      en: "4:00pm - Open End",
      hr: "16:00 - otvoreno",
      he: "16:00",
    },
    DATE: {
      en: "May 30th 2024",
      hr: "30. svibnja 2024",
      he: "30 במאי 2024",
    },
    ADDRESS: {
      en: "Am Weitenbach 1",
      hr: "Am Weitenbach 1",
      he: "Am Weitenbach 1",
    },
    COUNTRY: {
      en: "3652 Leiben, Austria",
      hr: "3652 Leiben, Austrija",
      he: "3652 Leiben, אוסטריה",
    },
  };

  return (
    <div className="relative bg-main-light pt-10 z-40">
      <div className="w-full">
        <Slider />
      </div>
      <div className="box absolute left-1/2 -translate-x-1/2 bg-white z-50 max-w-[95%] md:max-w-4xl w-full py-8 -mt-14 shadow-lg">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="w-full flex items-center justify-center mb-2">
            <FlowerIcon icon={flowers["F11"]} />
          </div>
          <div className="mt-2 w-full">
            <span className="superheadline text-[16px] md:text-xl ltr:font-davidlibre rtl:font-bonanova w-max mx-auto text-main-yellow flex items-center relative uppercase">
              {copy.SUPER[lang]}
            </span>
          </div>
          <h2 className="mt-4 text-4xl md:text-5xl font-bonanova font-medium">
            <span className="block text-main-green">{copy.TITLE[lang]}</span>
          </h2>
          <div className="my-7 px-8 md:px-0 w-full md:w-[440px] mx-auto text-center text-main text-lg">
            {copy.SUB[lang]}
          </div>
          <div className="mb-10 flex items-center justify-between w-full px-8 md:px-0 md:w-[440px] ltr:font-davidlibre rtl:font-bonanova">
            <div className="flex flex-col items-start">
              <div className="text-xl md:text-2xl font-bold">
                {copy.WHEN[lang]}
              </div>
              <div className="text-[16px] md:text-lg text-main leading-5">
                {copy.TIME[lang]}
                <br />
                {copy.DATE[lang]}
              </div>
            </div>
            <div className="flex flex-col items-end">
              <div className="text-xl md:text-2xl font-bold">
                {copy.WHERE[lang]}
              </div>
              <div className="text-[16px] md:text-lg text-main ltr:text-right rtl:text-left leading-5">
                {copy.ADDRESS[lang]}
                <br />
                {copy.COUNTRY[lang]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Home = () => {
  const location = useLocation();
  const { rsvpRef } = useMainContext();

  useEffect(() => {
    if (location.pathname.includes('rsvp') && rsvpRef.current) {
      window.scroll({
        top: rsvpRef.current.offsetTop + rsvpRef.current.clientHeight * 1.5,
        behavior: "smooth",
      });
    }
  }, [location, rsvpRef]);

  return (
    <>
      <LocationAndTimeSection />
      <TimelineSection />
      <RsvpSection />
      <InformationSection />
      <CountdownSection />
    </>
  );
};
