import { useState, useRef, useEffect } from "react";
import { Section, ToastMessage, Lang, DietaryRestriction } from "../types";
import { transportOptions } from "../Data/transport";

export const useMain = ({ lang }: { lang: Lang }) => {
  // const [section, setSection] = useState<Section>("MAIN");
  const [submitted, setSubmitted] = useState(false);
  const [noName, setNoName] = useState(false);
  const [toast, setToast] = useState<ToastMessage | null>(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [plusOne, setPlusOne] = useState(false);
  const [plusOneName, setPlusOneName] = useState("");
  const [bringingKids, setBringingKids] = useState(false);
  const [kids, setKids] = useState("");
  const [selectedDiet, setSelectedDiet] = useState<DietaryRestriction[]>([]);
  const [selectedTransport, setSelectedTransport] = useState(
    transportOptions[0]
  );
  const [info, setInfo] = useState("");
  const [formDivHeight, setFormDivHeight] = useState(0);
  const formDivRef = useRef<HTMLFormElement>(null);
  const rsvpRef = useRef<HTMLDivElement>(null);
  // const [lang, setLang] = useState<Lang>("en");

  useEffect(() => {
    if (formDivRef.current) {
      setFormDivHeight(formDivRef.current.clientHeight);
    }
  }, [plusOne, bringingKids]);

  return {
    name,
    setName,
    email,
    setEmail,
    whatsapp,
    setWhatsapp,
    plusOne,
    setPlusOne,
    plusOneName,
    setPlusOneName,
    bringingKids,
    setBringingKids,
    kids,
    setKids,
    selectedDiet,
    setSelectedDiet,
    selectedTransport,
    setSelectedTransport,
    info,
    setInfo,
    formDivHeight,
    formDivRef,
    noName,
    setNoName,
    toast,
    setToast,
    submitted,
    setSubmitted,
    rsvpRef,
    // section,
    // setSection,
    lang,
    // setLang,
  };
};
