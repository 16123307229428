import { Listbox, RadioGroup, Switch, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Fragment, useState } from "react";
import {
  CopyItem,
  DietaryRestriction,
  TransportOption,
} from "../types";
import { useMainContext } from "../Context/MainContext";
import { useBreakpoint } from "../Hooks/useBreakpoint";
import axios from "axios";
import { LoadingSpinner } from "./LoadingSpinner";
import { dietaryRestrictions } from "../Data/diets";
import { transportOptions } from "../Data/transport";

const Toggle = ({
  label,
  description,
  value,
  onChange,
}: {
  label: string;
  description: string;
  value: boolean;
  onChange: (value: boolean) => void;
}) => {
  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex-grow flex flex-col">
        <Switch.Label
          as="span"
          className="text-sm font-medium text-gray-900"
          passive
        >
          {label}
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
          {description}
        </Switch.Description>
      </span>
      <Switch
        checked={value}
        onChange={onChange}
        className={clsx(
          value ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        )}
      >
        <span
          aria-hidden="true"
          className={clsx(
            value ? "ltr:translate-x-4 rtl:-translate-x-4" : "translate-x-0",
            "pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
    </Switch.Group>
  );
};

const Input = ({
  label,
  error,
  ...props
}: {
  label: string;
  error?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <>
      <label
        htmlFor={props.id}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <input
        className={clsx(
          "outline-none w-full block bg-transparent border-b border-b-black/10 py-2 pr-[0.3rem]",
          {
            "border-b-tr-red text-tr-red focus:ring-tr-red focus:border-tr-red":
              !!error,
          }
        )}
        {...props}
      />
    </>
  );
};

const Select = ({
  selected,
  setSelected,
}: {
  selected: DietaryRestriction[];
  setSelected: (value: DietaryRestriction[]) => void;
}) => {
  const { lang } = useMainContext();
  const copy: CopyItem = {
    DIET: {
      en: "Do you have any dietary restrictions?",
      hr: "Imate li bilo kakve prehrambene restrikcije?",
      he: "הגבלות תזונתיות?"
    },
    SELECT: {
      en: "Select dietary restrictions",
      hr: "Odaberite prehrambene restrikcije",
      he: "בחירה"
    },
    ALL_SELECTED: {
      en: "All dietary restrictions",
      hr: "Sve prehrambene restrikcije",
      he: "כל האילוצים התזונתיים",
    },
  };
  return (
    <Listbox
      value={selected}
      onChange={(c: any) => {
        const exists = selected.find((s) => s.id === c.id);
        if (exists) {
          setSelected(selected.filter((s) => s.id !== c.id));
        } else {
          setSelected([...selected, c]);
        }
      }}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            {copy.DIET[lang]}
          </Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm ltr:pl-3 rtl:pr-3 ltr:pr-10 rtl:pl-10 py-2 ltr:text-left rtl:text-right cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm">
              <span className="block truncate">
                {selected.length === 0
                  ? copy.SELECT[lang]
                  : selected.length === dietaryRestrictions.length
                    ? copy.ALL_SELECTED[lang]
                    : selected.map((dietRest) => dietRest.title[lang]).join(", ")}
              </span>
              <span className="absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-2 rtl:pl-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-50">
                {dietaryRestrictions.map((dietRest) => (
                  <Listbox.Option
                    key={dietRest.id}
                    className={({ active }) =>
                      clsx(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "cursor-default select-none relative py-2 ltr:pl-3 rtl:pr-3 ltr:pr-9 rtl:pl-9"
                      )
                    }
                    value={dietRest}
                  >
                    {({ active }) => (
                      <>
                        <span
                          className={clsx(
                            selected.includes(dietRest)
                              ? "font-bold"
                              : "font-normal",
                            "block truncate"
                          )}
                        >
                          {dietRest.title[lang]}
                        </span>

                        {selected.includes(dietRest) ? (
                          <span
                            className={clsx(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-4 rtl:pl-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

const Radio = ({
  selected,
  setSelected,
}: {
  selected: TransportOption;
  setSelected: (value: TransportOption) => void;
}) => {
  const { lang } = useMainContext();
  const copy: CopyItem = {
    TRANSPORT: {
      en: "How you will be arriving at the wedding?",
      hr: "Kako ćete stići na vjenčanje?",
      he: "איך תגיע לחתונה?",
    },
  };
  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className="block text-sm font-medium text-gray-700 mb-1">
        {copy.TRANSPORT[lang]}
      </RadioGroup.Label>
      <div className="bg-white rounded-md -space-y-px">
        {transportOptions.map((transport, transportIdx) => (
          <RadioGroup.Option
            key={transport.name}
            value={transport}
            className={({ checked }) =>
              clsx(
                transportIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                transportIdx === transportOptions.length - 1
                  ? "rounded-bl-md rounded-br-md"
                  : "",
                checked
                  ? "bg-indigo-50 border-indigo-200 z-10"
                  : "border-gray-200",
                "relative border px-4 py-1.5 flex cursor-pointer focus:outline-none"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={clsx(
                    checked
                      ? "bg-indigo-600 border-transparent"
                      : "bg-white border-gray-300",
                    active ? "ring-2 ring-offset-2 ring-indigo-500" : "",
                    "h-3 w-3 mt-[3px] cursor-pointer rounded-full border flex items-center justify-center"
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1 h-1" />
                </span>
                <div className="ltr:ml-3 rtl:mr-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={clsx(
                      checked ? "text-indigo-900" : "text-gray-900",
                      "block text-sm font-medium"
                    )}
                  >
                    {transport.title[lang]}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={clsx(
                      checked ? "text-indigo-700" : "text-gray-500",
                      "block text-sm"
                    )}
                  >
                    {transport.description[lang]}
                  </RadioGroup.Description>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export const RSVP = () => {
  const {
    name,
    setName,
    email,
    setEmail,
    whatsapp,
    setWhatsapp,
    plusOne,
    setPlusOne,
    plusOneName,
    setPlusOneName,
    bringingKids,
    setBringingKids,
    kids,
    setKids,
    selectedDiet,
    setSelectedDiet,
    selectedTransport,
    setSelectedTransport,
    info,
    setInfo,
    formDivRef,
    formDivHeight,
    noName,
    setNoName,
    setToast,
    submitted,
    setSubmitted,
    lang,
  } = useMainContext();

  const { screen } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const copy: CopyItem = {
    NAME: {
      en: "Name",
      hr: "Ime",
      he: "שם",
    },
    EMAIL: {
      en: "Email",
      hr: "Email",
      he: "אימייל",
    },
    WHATSAPP: {
      en: "WhatsApp",
      hr: "WhatsApp",
      he: "וואטסאפ",
    },
    PLUS_ONE: {
      en: "Are you bringing a plus one?",
      hr: "Dovodite li plus jedan?",
      he: "מגיע איתך אדם נוסף?"
    },
    PLUS_ONE_SUB: {
      en: "If you are, please let us know their name.",
      hr: "Ako jeste, molimo vas da nam kažete njihovo ime.",
      he: "במידה וכן, מה השם?"
    },
    PLUS_ONE_NAME: {
      en: "Name",
      hr: "Ime",
      he: "שם",
    },
    BRINGING_KIDS: {
      en: "Are you bringing kids?",
      hr: "Dovodite li djecu?",
      he: "האם אתה מביא ילדים?",
    },
    BRINGING_KIDS_SUB: {
      en: "If you are, please let us know their names and ages.",
      hr: "Ako jeste, molimo vas da nam kažete njihova imena i godine.",
      he: "במידה וכן, מה שמם וגילם?"
    },
    KIDS: {
      en: "Names and ages",
      hr: "Imena i godine",
      he: "שמות וגילאים",
    },
    INFO: {
      en: "Anything else we should know?",
      hr: "Imate li još nešto što bismo trebali znati?",
      he: "עוד משהו שאנחנו צריכים לדעת?",
    },
    SUBMIT: {
      en: "Submit",
      hr: "Pošalji",
      he: "שלח",
    },
    ERROR_TITLE: {
      en: "Unable to Submit",
      hr: "Nije moguće poslati",
      he: "לא ניתן לשלוח",
    },
    ERROR_MESSAGE: {
      en: "There was an error submitting your entry. Please try again.",
      hr: "Došlo je do pogreške prilikom slanja vašeg unosa. Molimo pokušajte ponovno.",
      he: "הייתה שגיאה בשליחת האישור שלך. אנא נסה שוב.",
    },
    SUCCESS_TITLE: {
      en: "Thank You",
      hr: "Hvala",
      he: "תודה",
    },
    SUCCESS_MESSAGE: {
      en: "We can't wait to see you at the wedding!",
      hr: "Jedva čekamo da vas vidimo na vjenčanju!",
      he: "אנחנו לא יכולים לחכות לראות אותך בחתונה!",
    },
    SUBMIT_ANOTHER: {
      en: "Submit Another",
      hr: "Pošalji još jedan",
      he: "שלח אישור אחד",
    },
    TOAST_ERROR_TITLE: {
      en: "Missing Information",
      hr: "Nedostaju informacije",
      he: "מידע חסר",
    },
    TOAST_ERROR_MESSAGE: {
      en: "Please enter your name",
      hr: "Molimo unesite svoje ime",
      he: "אנא הזן את שמך",
    },
    TOAST_SUCCESS_TITLE: {
      en: "Submitted",
      hr: "Podneseno",
      he: "נשלח",
    },
    TOAST_SUCCESS_MESSAGE: {
      en: "Thank you for submitting your RSVP",
      hr: "Hvala vam što ste poslali svoj RSVP",
      he: "תודה ששלחת את האישור שלך",
    },
  };

  if (error) {
    return (
      <div
        className="flex flex-col items-center justify-center w-full"
        style={{ height: screen === "mobile" ? "auto" : formDivHeight }}
      >
        <h2 className="mt-4 text-2xl md:text-3xl ltr:font-davidlibre rtl:font-bonanova font-medium pt-10 md:pt-0">
          <span className="block text-main-green">
            {copy.ERROR_TITLE[lang]}
          </span>
        </h2>
        <div className="mx-auto text-center text-main text-[16px] px-2 mt-5 pb-20 md:pb-0">
          {copy.ERROR_MESSAGE[lang]}
        </div>{" "}
      </div>
    );
  }

  if (submitted) {
    return (
      <div
        className="flex flex-col items-center justify-center w-full"
        style={{ height: screen === "mobile" ? "auto" : formDivHeight }}
      >
        <h2 className="text-2xl md:text-3xl ltr:font-davidlibre rtl:font-bonanova font-medium pt-10 md:pt-0">
          <span className="block text-main-green">
            {copy.SUCCESS_TITLE[lang]}
          </span>
        </h2>
        <div className="mx-auto text-center text-main text-[16px] px-2 mt-5 pb-10 md:pb-0">
          {copy.SUCCESS_MESSAGE[lang]}
        </div>
        <button
          type="submit"
          className="w-fit mb-8 md:mb-0 bg-main/90 md:mt-8 border border-transparent shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-main/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-tint"
          onClick={() => {
            setSubmitted(false);
            setName("");
            setEmail("");
            setWhatsapp("");
            setPlusOne(false);
            setPlusOneName("");
            setBringingKids(false);
            setKids("");
            setSelectedDiet([]);
            setSelectedTransport(transportOptions[0]);
            setInfo("");
          }}
        >
          {copy.SUBMIT_ANOTHER[lang]}
        </button>
      </div>
    );
  }

  return (
    <form
      ref={formDivRef}
      className="max-w-[90%] md:max-w-[95%] w-full mx-auto text-main"
      onSubmit={(e) => e.preventDefault()}
    >
      <div>
        <div>
          <Input
            label={copy.NAME[lang]}
            id="your-name"
            name="your-name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={noName ? "Please enter your name" : ""}
            error={noName}
            onFocus={() => setNoName(false)}
          />
        </div>
        <div className="mt-4">
          <Input
            label={copy.EMAIL[lang]}
            id="email-address"
            name="email-address"
            autoComplete="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mt-4">
          <Input
            label={copy.WHATSAPP[lang]}
            id="whatsapp-address"
            name="whatsapp-address"
            autoComplete="whatsapp"
            type="tel"
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
          />
        </div>
        <div className="mt-4">
          <Toggle
            label={copy.PLUS_ONE[lang]}
            description={copy.PLUS_ONE_SUB[lang]}
            value={plusOne}
            onChange={setPlusOne}
          />
        </div>
        {plusOne && (
          <div className="mt-4">
            <Input
              label={copy.PLUS_ONE_NAME[lang]}
              id="plusOneName"
              name="plusOneName"
              type="text"
              value={plusOneName}
              onChange={(e) => setPlusOneName(e.target.value)}
            />
          </div>
        )}
        <div className="mt-4">
          <Toggle
            label={copy.BRINGING_KIDS[lang]}
            description={copy.BRINGING_KIDS_SUB[lang]}
            value={bringingKids}
            onChange={setBringingKids}
          />
        </div>
        {bringingKids && (
          <div className="mt-4">
            <Input
              label={copy.KIDS[lang]}
              id="kids"
              name="kids"
              type="text"
              value={kids}
              onChange={(e) => setKids(e.target.value)}
            />
          </div>
        )}
        <div className="mt-4">
          <Select selected={selectedDiet} setSelected={setSelectedDiet} />
        </div>
        <div className="mt-4">
          <Radio
            selected={selectedTransport}
            setSelected={setSelectedTransport}
          />
        </div>
        <div className="mt-4">
          <Input
            label={copy.INFO[lang]}
            id="info"
            name="info"
            type="text"
            value={info}
            onChange={(e) => setInfo(e.target.value)}
          />
        </div>
      </div>
      <button
        type="submit"
        disabled={loading}
        className={clsx(
          "w-full mb-4 md:mb-0 mt-6 bg-main-light border border-transparent shadow-sm py-2 px-4 text-sm font-medium text-black hover:bg-main-tint focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-tint",
          { "flex items-center justify-center": loading }
        )}
        onClick={async () => {
          const data = {
            name,
            email,
            whatsapp,
            plusOne,
            plusOneName,
            bringingKids,
            kids,
            selectedDiet: selectedDiet.map((d) => d.name).join(", "),
            selectedTransport: selectedTransport.name,
            info,
          };

          if (name === "") {
            setNoName(true);
            setToast({
              title: copy.TOAST_ERROR_TITLE[lang],
              message: copy.TOAST_ERROR_MESSAGE[lang],
              type: "error",
            });
            setTimeout(() => {
              setToast(null);
            }, 3000);
            return;
          }

          try {
            setLoading(true);
            const res = await axios.post(
              "https://api.moishandpetra.com/rsvp",
              data
            );

            if (res.status === 200) {
              setToast({
                title: copy.TOAST_SUCCESS_TITLE[lang],
                message: copy.TOAST_SUCCESS_MESSAGE[lang],
                type: "success",
              });
              setTimeout(() => {
                setToast(null);
              }, 3000);
            }
            setLoading(false);
            setSubmitted(true);
            if (screen === "mobile") {
              setPlusOne(!plusOne);
            }
          } catch (error) {
            setError(true);
          }
        }}
      >
        {loading ? <LoadingSpinner /> : copy.SUBMIT[lang]}
      </button>
    </form>
  );
};
